

const PreFooter = () => {
    return ( 
        <>  
            <div className="wrapper">
                <div className="content-container">
                    <div className="prefooter">
                        <p className="prefooter"> $DOWC is a meme coin with no intrinsic value or expectation of financial <br />return. The coin is completely useless and for entertainment purposes only.</p> 
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default PreFooter;