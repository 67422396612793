import './index.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import  Dwc  from './pages/dwc';
import  Halmet  from './pages/halmet';
import React from 'react';

function App() {
  return (
    <>
      <BrowserRouter>
      <Routes>
          <Route path="/" element={<Dwc/>} />
          <Route path="/helmet" element={<Halmet/>} />
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;