import tokenomicsimg from "../assets/images/tokenomicsimg.svg";

const Roadmap = () => {
    return ( 
        <>
            <div  className="roadmap">
                <div className="wrapper">
                    <div className="roadmap-container">
                        <div className="roadmap-container-content">
                            <h2>ROADMAP</h2>
                            <ul className="roadmap-content">
                                <li><p className="roadmap-content-item-one"><span>Phase 1</span> &nbsp; (Presale): Memes, Good vibes, Preparing for Takeoff</p></li>
                                <li><p className="roadmap-content-item-two"><span>Phase 2</span> &nbsp; (Public, DEX): Swap, Vibe, HODL, Repeat</p></li>
                                <li><p className="roadmap-content-item-three"><span>Phase 3</span> &nbsp; (CEX): $DOWC Takeover, Staking</p></li>
                                <li><p className="roadmap-content-item-for"><span>Phase 4</span> &nbsp; Expanding $DOWC Ecosystem</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default Roadmap;