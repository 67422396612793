import graf from "../assets/images/graf.gif";
import dghalmet from "../assets/images/dghalmet.jpg";
import { Link } from 'react-router-dom';

const Presale = () => {
    return ( 
        <>
            <div  id="presale" className="presale">
                <div className="wrapper">
                    <div className="presale-container">
                        <div className="presale-container-content">
                            <h2>PUT ON A HELMET:</h2>
                            <ul>
                                <li>
                                    <img src={dghalmet} alt="" />
                                </li>
                                <li>
                                    <a className="button" href="https://helmets.dowc.xyz/">HELMET TOOL</a>
                                    <p>Join the $DOWC army <br /> by putting on a helmet</p>
                                </li>
                            </ul>
                            <div className="presale-container-graf">
                                <img src={graf} alt="" />
                            </div>
                            <div className="presale-container-dowc">
                                <p>DOWС</p>
                                <p>DOWС</p>
                                <p>DOWС</p>
                                <p>DOWС</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default Presale;