import React from 'react';
import background from "../assets/images/background.gif";
import halmetdog from "../assets/images/halmetdog.png";
import dowc from "../assets/images/dowc.png";

const halmet = () => {
   return (
   <>
      <div class="halmet">
            <div class="halmet-wrapper">
                <div class="conteiner-img">
                    <div class="continer-content">
                        <div class="continer-content-parent">
                            <h2>PUT <br /> ON A HALMET</h2>
                            <div class="ctx-parent bl">
                                <div class="cropper">
                                    <span class="text">
                                        Drag and drop image file <br/>
                                        <span class="normal">or</span> <br/>
                                        <span class="normal">Click for choose</span>
                                    </span>
                                    <input type="file" id="file_image"/>
                                </div>
                                <canvas id="editor"><a href="https://www.google.ru/chrome/browser/desktop/index.html">browser</a></canvas>
                            </div>
                            
                            <div class="tools-panel bl">
                                <ul class="halmet-conteiner-button">
                                    <li>
                                        <a href="#" class="save-pic disable"><span class="fa fa-save">save</span></a>
                                    </li>
                                    <li>
                                        <a href="#" class="trasher disable"><span class="fa fa-trash">delite</span></a>
                                    </li>
                                    <li>
                                        <a href="#" class="clear disable"><span class="fa fa-window-close-o">clear</span></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class='continer-stickers'>
                            <img src={dowc} alt="" />
                            <div class="stickers-parent bl">
                                <ul>
                                    <li>
                                        <img src="https://i.ibb.co/F6tSTtG/ios-scan-909777910-1-removebg-preview.png" alt=""/>
                                    </li>
                                    <li>
                                        <img src="https://i.ibb.co/TPLb4dX/ios-scan-1459800431-1-removebg-preview.png" alt=""/>
                                    </li>
                                    <li>
                                        <img src="https://i.ibb.co/9N1PxHf/ios-scan-2479078363-1-removebg-preview.png" alt=""/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class='continer-background'>
                        <div class='background-gif'>
                            <img src={background} alt="" />
                        </div>
                        <div class='conteiner-dog'>
                            <img src={halmetdog} alt="" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </>
    )
}
 
export default halmet;