import tokenomicsimg from "../assets/images/tokenomicsimg.png";

const Tokenomics = () => {
    return ( 
        <>
            <div className="tokenomics">
                <div className="wrapper">
                    <div className="tokenomics-container">
                        <div className="tokenomics-container-img">
                            <img src={tokenomicsimg} alt="" />
                        </div>
                        <div className="tokenomics-container-content">
                            <h2>TOKENOMICS</h2>
                            <ul className="tokenomics-content">
                                <li><p>TOTAL SUPPLY</p></li>
                                <li><p>No Taxes</p></li>
                                <li><p>No Bullshit</p></li>
                                <li><p>LP tokens are Burnt</p></li>
                                <li><p>Contract Ownership is Renounced</p></li>
                            </ul>
                            <h3>1,000,000,000</h3>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default Tokenomics;