import leo from "../assets/images/leodog.gif";
import dog from "../assets/images/dogsexіп.gif";
import dowg from "../assets/images/dowg.svg";

const Content = () => {
    return ( 
        <>
            <div className="wrapper">
                <div className="content-container">
                    <div className="content-container-content">
                        <div className="svg-cont1">
                            <img src={dowg} alt="" />
                        </div>
                        <div className="svg-cont2">
                            <img src={dowg} alt="" />
                        </div>
                        <div className="svg-cont3">
                            <img src={dowg} alt="" />
                        </div>
                        <div className="svg-cont4">
                            <img src={dowg} alt="" />
                        </div>
                        <div className="content-cont">
                            <div className="content">
                            <p className="content">
                                #DOWC is greedy AF MF <br />
                                And don't share cash with jeets,<br />
                                fudders and paperhanders <br />
                                But gladly share cash with
                                <br /> diamondhanders and supporters<br /> of the project <br />
                                Be smart and be frens with $DOWC
                                </p>
                            </div>
                            <div className="content-img">
                                <img src={leo} alt="" />
                            </div>
                        </div>
                        <div className="content-cont-dog">
                            <div className="content-img-dog">
                                <img src={dog} alt="" />
                            </div>
                            <div className="content-dog">
                                <p className="content"> 
                                    DOWC is literally Dog Wif Cash. <br />
                                    Dog can share cash with you, Degen. <br />
                                    All you need is just to trust <br /> the process and HODL <span>$DOWC</span>
                                </p>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default Content;