import dog from "../assets/images/dog.png";
import dogwifcash from "../assets/images/DWC.png";
import dowc from "../assets/images/dowc.png";
import telegram from "../assets/images/telegram.svg";
import x from "../assets/images/x.svg";
import TwentyTwenty from 'react-twentytwenty';
import doglitl from "../assets/images/doglitl.png";
import { Link } from 'react-router-dom';


const Header = () => {

    return ( 
        <>
            <header className="header">
                <div className="wrapper">
                    <div className="header-container">
                        <div className="header-container-title">
                            <img src={dowc} alt="" />
                        </div>
                        <div className="header-container-content">
                            <div className="dogwifcash">
                                <img src={dogwifcash} alt="" />
                            </div>
                            <div className="dog">
                                <div className='twenty-conteiner'>
                                    <TwentyTwenty
                                        left={<img className='dog-left' src={doglitl} />}
                                        right={<img className='dog-right' src={dog} />}
                                        slider={<div className="slider" />}
                                        defaultPosition={0.19}
                                    />
                                </div>
                            </div>
                            <div className="social">
                                <ul>
                                    <li><a href="https://t.me/dowcsol"><img src={telegram} alt="" /></a></li>
                                    <li className="social-last-item"><a href="https://x.com/dowcsol"><img src={x} alt="" /></a></li>
                                </ul>
                                <div>
                                    <a className="button" href="https://raydium.io/swap/?inputCurrency=sol&inputSymbol=DOWC&outputCurrency=5qz2sWwWTJqsmQfEXtpn64P96KhRpPDZT7qUrySGdoWN&fixed=out">Buy Now</a>
                                </div>
                                <div>
                                    <p>Powered <br />by Solana</p>
                                </div>
                                <div>
                                    <h3>CA: 5qz2sWwWTJqsmQfEXtpn64P96KhRpPDZT7qUrySGdoWN</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
 
export default Header;